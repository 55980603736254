<template>
  <PullRefresh :on-refresh="onRefresh">
    <v-card class="profile-info-container news-container">
      <v-toolbar class="profile-info-toolbar">
        <v-btn @click.prevent="$router.replace('/')" class="btn-back">
          <v-icon>mdi-chevron-left</v-icon>
          <span class="ml-2">Back</span>
        </v-btn>
      </v-toolbar>
      <v-card class="profile-info-card">
        <div class="font-20 fw-500 text-left px-8 mb-4">
          Latest News
        </div>
        <div v-if="$store.state.news.data.length" class="news-info-container">
          <div v-for="(data, index) in $store.state.news.data" class="news-info-card" :key="index">
            <div class="news-img" @click="$router.push('?title=' + data.slug), newsDetail(data)" :style="`background-image: url('${data.cover}')`"></div>
            <div class="news-content" @click="$router.push('?title=' + data.slug), newsDetail(data)">
              <div class="news-title">
                {{ data.title }}
              </div>
              <div class="news-desc" v-html="data.content">
              </div>
              <v-row no-gutters class="news-card-footer justify-space-between">
                <v-col cols="auto" class="align-self-center">
                  {{ $moment(data.created).format('MMM D YYYY') }}
                </v-col>
                <v-col cols="auto" class="align-self-center">
                  Read More
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="loadMore()" class="pt-2 pb-8">
            <LittleLoading :show="isLoading" />
            <v-btn v-if="!isLoading" small depressed @click.prevent="loadNewsData()" class="load-button rounded-lg">
              Load more
            </v-btn>
          </div>
        </div>
        <div v-else class="news-info-container">
          <img src="../assets/illustrations/blank_news.svg" class="blank-news" alt="Empty News">
          <div class="blank-news-caption">
            Stay tuned for updates.<br/>No latest news at the moment
          </div>
        </div>
      </v-card>

      <v-dialog v-if="newsInfo.show" v-model="newsInfo.show" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
        <v-card class="profile-info-container">
          <v-toolbar class="profile-info-toolbar">
            <v-btn @click="closeDialog" class="btn-back">
              <v-icon>mdi-chevron-left</v-icon>
              <span class="ml-2">Back</span>
            </v-btn>
          </v-toolbar>
          <v-card class="profile-info-card px-8">
            <div class="font-20 fw-500 text-left mb-4">
              {{ newsInfo.data.title }}
            </div>
            <img :src="`${newsInfo.data.cover}`" class="img-detail-news mb-5" alt="News Cover">
            <div v-html="newsInfo.data.content" class="font-14 text-justify pb-10"></div>
          </v-card>
        </v-card>
      </v-dialog>

    </v-card>
  </PullRefresh>
</template>
<script>
import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'News',
  data: (vm) => ({
    newsData: [],
    newsInfo: {
      show: false,
      data: {}
    },
    page: 0,
    isLoading: false
  }),
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.onRefresh()
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })

    this.loadNewsData()
  },
  watch: {
    '$route' (to, from) {
      if (to.query) {
        this.loadNewsData()
      }
    }
  },
  methods: {
    onRefresh () {
      const refreshData = () => {
        this.loadNewsData()
        if (localStorage.getItem('t') && localStorage.getItem('push_token') && !this.$store.state.admin) {
          this.$store.dispatch('CHECK_T', localStorage.getItem('push_token')).then((res) => {
            if (!res) {
              this.$toastNotif({
                message: 'Oops! Looks like your account sneaked onto another device. Please re-login to get back in action!',
                type: 'info',
                timer: 3500
              })
              this.$store.dispatch('DO_LOGOUT')
            }
          })
        }
      }

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          refreshData()
          resolve()
        }, 1000)
      })
    },
    loadMore () {
      if (this.$store.state.news.data.length) {
        return this.$store.state.news.data.length < parseInt(this.$store.state.news.total)
      } else {
        return false
      }
    },
    async loadNewsData () {
      this.isLoading = true
      if (this.loadMore() || parseInt(this.page) === 0) {
        this.page = (parseInt(this.page) || 0) + 1
      }
      await this.$store.dispatch('GET_NEWS', `page=${(this.page || 1)}&limit=15`).then((res) => {
        setTimeout(() => {
          if (res.status) {
            this.newsData = res.data.data
            this.isLoading = false

            if (this.$route.query && this.$route.query.title) {
              this.newsData.data.forEach((data) => {
                if (this.$route.query.title === data.slug) {
                  this.newsDetail(data)
                }
              })
            }
          }
        }, 500)
      })
    },
    newsDetail (data) {
      this.newsInfo.show = true
      this.newsInfo.data = data
    },
    closeDialog () {
      this.$router.push('/news')
      this.newsInfo.show = false
      this.newsInfo.data = []
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/global-vars';
@import '../assets/scss/fonts';

.news-info-container{
  position: relative;
  padding: 0 30px;
  height: calc(100vh - 256px);
  overflow-y: auto;
  .news-info-card{
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: 16px;
    .news-img{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 27%;
      max-width: 90px;
      height: 32vw;
      max-height: 120px;
      border-radius: 20px 0 0 20px;
      background-color: $white;
      background-size: cover;
      background-position: center;
      box-shadow: 2px 4px 14px rgba(0,0,0,.15);
      z-index: 1;
    }
    .news-content{
      position: absolute;
      left: 24%;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 36vh;
      max-height: 150px;
      border-radius: 20px;
      background-color: $white;
      box-shadow: 2px 6px 18px rgba(0,0,0,.15);
      text-align: left;
      padding: 8px 16px;
      z-index: 2;
      @media(min-width:500px){
        left: 20%;
      }
      .news-title{
        @include font-size(14px);
        @include line-clamp(2);
        font-weight: 600;
        margin-bottom: 4px;
      }
      .news-desc{
        @include font-size(12px);
        @include line-clamp(3);
      }
      .news-card-footer{
        position: absolute;
        bottom: 8px;
        left: 16px;
        right: 16px;
        @include font-size(12px);
        color: $grey;
        .col:last-of-type{
          color: $primary;
          font-weight: 500;
        }
      }
    }
  }
  .blank-news{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -60%);
    display: block;
    text-align: center;
    width: 55%;
  }
  .blank-news-caption{
    position: absolute;
    display: block;
    left: 50%;
    top: 60%;
    transform: translateX(-50%);
    width: 300px;
    height: auto;
    @include font-size(14px);
    font-weight: 500;
  }
}
.load-button:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: $danger !important;
  text-transform: capitalize;
  color: $white;
  min-width: 125px;
  min-height: 35px;
}
</style>
<style lang="scss" scoped>
.profile-info-container{
  border-radius: 0;
  height: calc(100vh - 70px);
  &.news-container{
    overflow: hidden;
  }
  .profile-info-card{
    padding: 42px 0 60px;
  }
  .img-detail-news{
    width: 100%;
    object-fit: contain;
    border-radius: 10px !important;
  }
}
</style>
